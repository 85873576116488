exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-how-to-choose-a-domain-name-js": () => import("./../../../src/pages/articles/how-to-choose-a-domain-name.js" /* webpackChunkName: "component---src-pages-articles-how-to-choose-a-domain-name-js" */),
  "component---src-pages-articles-seo-tips-and-tricks-to-boost-your-website-ranking-js": () => import("./../../../src/pages/articles/seo-tips-and-tricks-to-boost-your-website-ranking.js" /* webpackChunkName: "component---src-pages-articles-seo-tips-and-tricks-to-boost-your-website-ranking-js" */),
  "component---src-pages-articles-why-digital-marketing-matters-js": () => import("./../../../src/pages/articles/why-digital-marketing-matters.js" /* webpackChunkName: "component---src-pages-articles-why-digital-marketing-matters-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-applications-js": () => import("./../../../src/pages/custom-applications.js" /* webpackChunkName: "component---src-pages-custom-applications-js" */),
  "component---src-pages-ecommerce-solutions-js": () => import("./../../../src/pages/ecommerce-solutions.js" /* webpackChunkName: "component---src-pages-ecommerce-solutions-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-app-development-js": () => import("./../../../src/pages/mobile-app-development.js" /* webpackChunkName: "component---src-pages-mobile-app-development-js" */),
  "component---src-pages-packages-js": () => import("./../../../src/pages/packages.js" /* webpackChunkName: "component---src-pages-packages-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-project-details-js": () => import("./../../../src/pages/project-details.js" /* webpackChunkName: "component---src-pages-project-details-js" */),
  "component---src-pages-projects-1-js": () => import("./../../../src/pages/projects-1.js" /* webpackChunkName: "component---src-pages-projects-1-js" */),
  "component---src-pages-projects-2-js": () => import("./../../../src/pages/projects-2.js" /* webpackChunkName: "component---src-pages-projects-2-js" */),
  "component---src-pages-seo-digital-marketing-js": () => import("./../../../src/pages/seo-digital-marketing.js" /* webpackChunkName: "component---src-pages-seo-digital-marketing-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-term-condition-js": () => import("./../../../src/pages/term-condition.js" /* webpackChunkName: "component---src-pages-term-condition-js" */),
  "component---src-pages-ux-ui-design-js": () => import("./../../../src/pages/ux-ui-design.js" /* webpackChunkName: "component---src-pages-ux-ui-design-js" */),
  "component---src-pages-website-creation-js": () => import("./../../../src/pages/website-creation.js" /* webpackChunkName: "component---src-pages-website-creation-js" */)
}

